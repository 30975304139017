import * as types from '../constants';
import createHttp from '@dmm/create-http';
import get from 'lodash/get';
import {getConfig} from '../../config/portal';
import cache from 'memory-cache';
import { getBoatLoanConstants } from '../../constants/BoatLoans';

const cacheTime = 15 * 60 * 1000;

const setTridentRates = data => ({
  type: types.TRIDENT_SET_RATES,
  data
});

const setTridentLeadSubmitted = data => ({
  type: types.TRIDENT_LEAD_SUBMITTED,
  data
});

export const getFicoScores = (tridentRates) => {
  const rate = tridentRates?.[0] || [];
  const ficoScores = Object.keys(rate).filter(key => {
    return key.match(/^\d+-\d+$/);
  });
  return ficoScores;
};

export const getRatesFromTridentAPI = () => async (dispatch) => {
  let result;
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const { RATES_TRIDENT_API_URL } = boatLoansConstants;
  try {
    const cachedData = cache.get('rates');
    if (!cachedData) {
      const apiClient = createHttp({
        baseURL: get(getConfig(), 'boatLoans.tridentApi'),
      });
      const res = await apiClient.get(RATES_TRIDENT_API_URL);
      cache.put('rates', res.data, cacheTime);
      cache.put('rates-last-success', res.data);
      result = dispatch(setTridentRates(res.data));
    } else {
      result = dispatch(setTridentRates(cachedData));
    }
  } catch (err) {
    const data = get(err, 'response.data', err.toString());
    // eslint-disable-next-line no-console
    console.error(data.toString());
    const cachedLastSuccess = cache.get('rates-last-success');
    if (cachedLastSuccess) {
      result = dispatch(setTridentRates(cachedLastSuccess));
    }
  }
  return result;
};

export const setSubmittedPreQualifyForm = () => dispatch => {
  dispatch(setTridentLeadSubmitted(true));
};
